<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar por nº de pedido o fecha"
              />
              <b-button
                variant="primary"
                @click="editMaterialesRecepcion('new')"
              >
                <span class="text-nowrap">Recepción de material</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refMaterialesRecepcionListTable"
        class="position-relative"
        :items="fetchMaterialesRecepcion"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros coincidentes"
        :sort-desc.sync="isSortDirDesc"
        :busy="!fetchMaterialesRecepcion"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              class="align-middle"
              small
            />
            <strong class="ml-1">Cargando datos...</strong>
          </div>
        </template>

        <template #cell(fk_user)="data">
          <span>{{ data.item.user_name }}</span>
        </template>

        <template #cell(fk_proveedor)="data">
          <span>{{ data.item.proveedor_name }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- Editar -->
          <b-button
            :id="`edit-${data.item.id}`"
            variant="success"
            class="btn-icon mr-1 mb-1"
            @click="editMaterialesRecepcion(data.item.id)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-tooltip
            :target="`edit-${data.item.id}`"
            triggers="hover"
            placement="bottom"
            boundary="window"
          >
            <b>Editar</b>
          </b-tooltip>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de
              {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalMaterialesRecepcion"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import useMaterialesRecepcionList from './useMaterialesRecepcionList'
import materialesStoreModule from './materialesRecepcionStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,

    vSelect,
    ConfirmationModal,
  },
  data() {
    return {
      deleteModal: false,
      selectedItem: {},
    }
  },
  methods: {
    editMaterialesRecepcion(id) {
      this.$router.push({
        name: 'materiales-recepcion-edit',
        params: {
          id,
        },
      })
    },
  },
  setup() {
    const MATERIALES_RECEPCION_APP_STORE_MODULE_NAME = 'app-materiales-recepcion'

    // Register module
    if (!store.hasModule(MATERIALES_RECEPCION_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        MATERIALES_RECEPCION_APP_STORE_MODULE_NAME,
        materialesStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIALES_RECEPCION_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIALES_RECEPCION_APP_STORE_MODULE_NAME)
    })

    const {
      fetchMaterialesRecepcion,
      tableColumns,
      perPage,
      currentPage,
      totalMaterialesRecepcion,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMaterialesRecepcionListTable,
      refetchData,
    } = useMaterialesRecepcionList()

    return {
      fetchMaterialesRecepcion,
      tableColumns,
      perPage,
      currentPage,
      totalMaterialesRecepcion,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMaterialesRecepcionListTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
