import { render, staticRenderFns } from "./MaterialesRecepcionList.vue?vue&type=template&id=1573d526&scoped=true&"
import script from "./MaterialesRecepcionList.vue?vue&type=script&lang=js&"
export * from "./MaterialesRecepcionList.vue?vue&type=script&lang=js&"
import style0 from "./MaterialesRecepcionList.vue?vue&type=style&index=0&id=1573d526&lang=scss&scoped=true&"
import style1 from "./MaterialesRecepcionList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1573d526",
  null
  
)

export default component.exports